import "./style.less";

import { Empty, Form, Tabs } from "antd";
import PageHeader from "antd/es/page-header";
import Card from "antd/lib/card";
import { Col, Row } from "antd/lib/grid";
import Space from "antd/lib/space";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import VuiContainer from "../../../../@vodea/vodea-ui/components/Container";
import VuiUploadImages from "../../../../@vodea/vodea-ui/components/UploadImages";
import {
  counselingForm,
  getNoPhotoPlaceholder,
  handleBackendError,
  toFileList,
} from "../../../../functions/global";
import ScheduleRepository from "../../../../repositories/ScheduleRepository";
import { Schedule } from "../../../../entities/schedule";
import { Resource } from "../../../../entities/base/resource";
import FormItemView from "../../../../@vodea/vodea-ui/components/Form/FormItemView";
import StatusTag from "../../../../components/StatusTag";
import FormAction from "../../../../components/FormAction";
import CounselingForm from "../../../../components/CounselingForm";
import { useReactToPrint } from "react-to-print";
import Button from "antd/es/button";
import Typography from "antd/lib/typography";
import { useState } from "@hookstate/core";

const { Title } = Typography;

const { TabPane } = Tabs;

let title = "Schedule";

const AppScheduleView: React.FC<any> = () => {
  const componentToPrintRef = React.useRef(null);
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const pageTitle = t("common.text.detailItem", { item: title });

  const [schedule, setSchedule] = React.useState<Schedule>();
  const [isLoading, setIsLoading] = React.useState(false);

  const disable = useState(false);
  const [patientFormInstance] = Form.useForm();
  const [patientForm, setPatientForm] = React.useState<any>(null);

  const {
    psychologist,
    session_type,
    type,
    date,
    time_from,
    time_to,
    booking,
    status,
  } = schedule || {};

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    setIsLoading(true);
    const payload = {
      with: [
        "psychologist",
        "status",
        "booking.category",
        "booking.customer.photo",
        "booking.counselingForm.bookingFormAnswers.formQuestion",
        "booking.counselingForm.form",
        "booking.bookingForms.form",
        "booking.bookingForms.bookingFormAnswers.formQuestion",
        "booking.invoice",
        "booking.file",
        "booking.reports.file",
      ],
    };
    await ScheduleRepository.show(id, payload)
      .then((res: AxiosResponse<Resource<Schedule>>) => {
        const data = res.data?.data || {};

        let formTypeInternal = "Internal";
        if (data.session_type === "ASSESSMENT") {
          formTypeInternal = "Internal Assessment";
        }

        const patientFormData = data?.booking?.booking_forms?.find(
          (item: any) => item.type !== formTypeInternal,
        );

        if (patientFormData) {
          const counselingFormData = patientFormData.booking_form_answers?.map(
            (item: any) => {
              return item?.old_question || item?.form_question;
            },
          );
          setPatientForm({
            id: patientFormData.form_id,
            form_questions: counselingFormData,
            name: patientFormData?.form.name,
            description: patientFormData?.form.description,
          });

          const patientQuestionAnswer = counselingForm.toShow(
            patientFormData.booking_form_answers,
          );

          patientFormInstance.setFieldsValue(patientQuestionAnswer);
        }

        setSchedule(data);
        setIsLoading(false);
        disable.set(true);
      })
      .catch((e: AxiosError) => {
        console.log(e)
        handleBackendError(e, t("notification.error.default"));
        setIsLoading(false);
      });
  };

  const reactToPrintContent = React.useCallback(() => {
    return componentToPrintRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentToPrintRef.current, counselingForm]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Counseling Form",
    removeAfterPrint: true,
  });

  const headerExtra = useMemo(() => {
    const extra = [];

    if (patientForm) {
      extra.push(
        <Button onClick={handlePrint} key="1" type="primary">
          Download Counseling Form
        </Button>,
      );
    }

    return extra;
  }, [handlePrint, patientForm]);

  return (
    <>
      <Helmet>
        <title>{title} Detail</title>
      </Helmet>
      <VuiContainer bottomSpace={110}>
        <PageHeader
          className="default-page-header"
          onBack={() => navigate(-1)}
          title={pageTitle}
          extra={headerExtra}
          tags={status && <StatusTag title={_.get(status, "label", "")} />}
        />

        <Row gutter={[20, 20]}>
          <Col xs={24} lg={8}>
            <Space className="flex" direction="vertical" size={20}>
              <Card loading={isLoading}>
                <Space direction="vertical" size={16}>
                  <FormItemView
                    label="Psychologist"
                    value={_.get(psychologist, "name")}
                  />
                  <FormItemView
                    label="Type"
                    value={_.capitalize(session_type)}
                  />
                  <FormItemView label="Method" value={_.capitalize(type)} />
                </Space>
              </Card>
              {(_.get(booking, "number") ||
                _.get(booking, "invoice.number")) && (
                <Card loading={isLoading}>
                  <Space direction="vertical" size={16}>
                    <FormItemView
                      label="Booking Number"
                      customvalue={
                        <Link to={`/booking/${booking?.id}`} target="_blank">
                          <u>{_.get(booking, "number")}</u>
                        </Link>
                      }
                    />
                    <FormItemView
                      label="Invoice Number"
                      value={_.get(booking, "invoice.number")}
                    />
                  </Space>
                </Card>
              )}
            </Space>
          </Col>
          <Col xs={24} lg={16}>
            <Space className="flex" direction="vertical" size={20}>
              <Card loading={isLoading}>
                <Row>
                  <Col xs={24} lg={8}>
                    <FormItemView
                      label="Date"
                      value={moment(date).format("DD MMM YYYY")}
                    />
                  </Col>
                  <Col xs={24} lg={16}>
                    <FormItemView
                      label="Time"
                      value={`${time_from} - ${time_to}`}
                    />
                  </Col>
                </Row>
              </Card>
              <Card loading={isLoading} className="schedule-patient-section">
                <Tabs defaultActiveKey="online">
                  <TabPane
                    forceRender
                    tab={"Patient Information"}
                    key="patient-information"
                  >
                    <Space className="flex" direction="vertical" size={20}>
                      <Row gutter={[16, 16]}>
                        <Col xs={24} lg={8}>
                          <FormItemView
                            label="Patient Photo"
                            value={
                              _.get(booking, "customer.photo") ??
                              getNoPhotoPlaceholder()
                            }
                            type="file"
                          />
                        </Col>
                        <Col xs={24} lg={16}>
                          <Row gutter={[16, 30]}>
                            <Col xs={24} lg={12}>
                              <FormItemView
                                label="Patient Name"
                                value={_.get(booking, "customer.name")}
                              />
                            </Col>
                            <Col xs={24} lg={12}>
                              <FormItemView
                                label="Email"
                                value={_.get(booking, "customer.email")}
                              />
                            </Col>
                            <Col xs={24} lg={12}>
                              <FormItemView
                                label="Phone Number"
                                value={_.get(booking, "customer.phone_number")}
                              />
                            </Col>
                            <Col xs={24} lg={12}>
                              <FormItemView
                                label="Date of Birth"
                                value={moment(
                                  _.get(booking, "customer.date_of_birth"),
                                ).format("DD MMM YYYY")}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col xs={24} lg={8}>
                          <FormItemView
                            label="Gender"
                            value={_.get(booking, "customer.gender")}
                          />
                        </Col>
                        <Col xs={24} lg={16}>
                          <FormItemView
                            label="Address"
                            value={_.get(booking, "customer.address")}
                          />
                        </Col>
                      </Row>
                    </Space>
                  </TabPane>
                  {session_type === "ASSESSMENT" && (
                    <TabPane forceRender tab={"Report"} key="report">
                      {_.get(booking, "reports.length", 0) > 0 ? (
                        <Space size={20} wrap>
                          {booking?.reports?.map((item) => {
                            return (
                              <VuiUploadImages
                                disabled
                                value={toFileList(item)}
                              />
                            );
                          })}
                        </Space>
                      ) : (
                        <Empty />
                      )}
                    </TabPane>
                  )}
                  {session_type === "ASSESSMENT" && (
                    <TabPane forceRender tab={"Instruction"} key="instruction">
                      {_.get(booking, "assessment_instruction") ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              _.get(booking, "assessment_instruction") ?? "",
                          }}
                        />
                      ) : (
                        <Empty />
                      )}
                    </TabPane>
                  )}
                  <TabPane forceRender tab={"Notes"} key="notes">
                    {_.get(booking, "notes") ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: _.get(booking, "notes") ?? "",
                        }}
                      />
                    ) : (
                      <Empty />
                    )}
                  </TabPane>
                </Tabs>
              </Card>
            </Space>
          </Col>
        </Row>

        <FormAction formName={title} onCancel={() => navigate(-1)} />
      </VuiContainer>

      {Boolean(patientForm) && (
        <div style={{ display: "none" }}>
          <div ref={componentToPrintRef} id="counseling-form">
            <style type="text/css" media="print">
              {
                "\
                  @page { size: potrait;}\
                "
              }
            </style>
            <div className="counseling-form-container">
              <img className="logo mb24" src={"/images/logo.png"} alt="logo" />
              <Title className="page-title mb24" level={3}>
                {patientForm.name}
              </Title>
              <div
                className="page-subtitle"
                dangerouslySetInnerHTML={{
                  __html: patientForm.description,
                }}
              />
              <div className="mb24" />
              <Form form={patientFormInstance} layout={"vertical"}>
                <CounselingForm
                  formData={patientForm.form_questions}
                  disabled={disable.get()}
                  forPrint
                />
              </Form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AppScheduleView;
